.container{
    height: 100vh;
    width: 100vw;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0px;
    left:0px;
}

.logos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0);
    .texto-de-bienvenida{
        font-size: 30px;
    }
    .giacore-logo img{//Relación1.5
        width: 120px;
        margin-bottom: 5px;
    }
    .insercor-logo img{//Relación 2.89
        width: 100px;
    }
}

.bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #e0e0e0 50%, #adbac2 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }  
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

  