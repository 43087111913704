/* CUSTOM MODALS: */
.modal-right {
  padding-right: 0 !important;

  .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px;
  }

  .modal-content {
    height: 100vh;
  }

  .modal-header {
    height: 25px;
  }

  .modal-footer {
    justify-content: center;
  }

  .modal-footer-2 {
    justify-content: space-around;
    background-color: cyan;
  }

  .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important;
  }

  .modal.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.modal-left {
padding-left: 0 !important;

.modal-dialog {
  margin: 0 auto;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
  max-width: 380px;
}

.modal-content {
  min-height: 100%;
}

.modal-header {
  height: 25px;
}

.modal-footer {
  justify-content: center;
}
.modal-footer-2 {
  justify-content: space-around;
  background-color: cyan;
}

.modal.fade .modal-dialog {
  transform: translate(25%, 0) !important;
}

.modal.show .modal-dialog {
  transform: translate(0, 0) !important;
}
}

/* CUSTOM BUTTONS */
.pusheable-button{
  cursor: pointer;
}

.pusheable-button:hover{
transform: scale(1.025);
}

.pusheable-button:active{
box-shadow: 0 3px 0 rgb(220,220,220);
top: 3px;
}

/* CUSTOM DIV SHADOW */
.left-shadow-card{
-webkit-box-shadow: -7px 0px 5px 0px rgba(0,0,0,0.13);
-moz-box-shadow: -7px 0px 5px 0px rgba(0,0,0,0.13);
box-shadow: -7px 0px 5px 0px rgba(0,0,0,0.13);
}

/* CUSTOM BORDER FORM CONTROL */
.custom-border-form-control:focus{
border-color: rgb(54,126,247) !important;
box-shadow: 0 0 0 0.05rem rgb(54,126,247) !important;
}

.bordered-radius{
border-radius: 5px !important;
}

/* CUSTOM TOGGLE FOR FILTER MANAGER */
.filter-manager-quick-button{
padding: 5;
opacity: 0.5;
border-radius: 5px;
border: none !important;
background-color: transparent !important;
}

.filter-manager-quick-button:hover{
opacity: 1.0;
background-color: rgb(238,238,238);
}

/* CUSTOM PAGINATION BUTTONS */
.pagination-button{
color: rgba(32, 39, 140, 0.5) !important;
background-color: rgb(245,245,245) !important;  
border: none !important;
cursor: pointer !important;
}

.pagination-button:hover{
background-color: rgb(235,235,235) !important;  
}

.pagination-button:active{
background-color: rgb(235,235,235) !important;  
top: 3px;
}

/* RECHARTS CUSTOM */
.recharts-cartesian-axis-tick-value{
font-size: 11px;
text-transform: uppercase;
font-weight: bold;
}

.recharts-legend-item{
font-size: 11px;
font-weight: bold;
}

.recharts-cartesian-axis-tick{
font-size: 11px;
text-transform: uppercase;
font-weight: bold;
fill: #d1d1d1;
}

.recharts-legend-wrapper{
bottom: -5px !important;
}

.table-header{
text-align: center;
font-weight: bold;
color: rgba(32, 39, 140, 0.5); 
margin-bottom: 0px; 
font-size: 13px;
}

.modal-faq-body {
  display: flex;
  flex-direction: row;
}
.modal-faq-body-1{
  width: 30%;
}

.modal-faq-body-2{
  width: 80%;
  justify-content: center;
}

.modal-content-transparent{
margin-top: 20%!important;
}
.modal-content-transparent .modal-content{
border: none;
}
.modal-header-tranparent{
display: none!important;
}
.modal-body-image{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-body-tranparent{
background-color: transparent;
}
.modal-footer-2 {
  justify-content: space-around;
}
.modal-footer-tranparent{
display: none!important;
}
.modal-footer-tranparent-2{
  display: flex!important;
  flex-direction: column;
  }
.modal-footer-first-element{
  display: flex!important;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 5px;
  }

.modal-footer-third-element{
  display: flex!important;
  justify-content: space-between;
  flex-direction: row;
  }
.modal-grid-state{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0px;
}
.file-hyperlink{
cursor: pointer;
font-weight: 400;
}
.file-hyperlink:hover{
font-weight: bold;
text-decoration: underline;
}
.drop-down-button{
background-color: rgb(52,115,70)!important;
border-color:rgb(52,115,70)!important;
width: 162px;
min-height: 35px;
}
.drop-down-button:active{
top:3px;
}
.drop-down-button:hover{
opacity: .9;
background-color: rgb(52,115,70)!important;
}
.drop-down-button svg{
font-size: 20px;
padding-left: 5px;
}
.drop-down-descargas{
transform: translate(180px, -43px)!important;
}
.drop-down-descargas::before{
left: -2px!important;
top: 53px!important
}
.drop-down-descargas::after{
background: none!important;
}
.ancla-plantilla{
color: #545b62;
}

.ancla-plantilla:hover{
color: #545b62;
}

.ot-plantilla{
  color: white;
  text-decoration: none;
  }

.ot-plantilla:link {
  text-decoration: none;
}

.ot-plantilla:visited {
  text-decoration: none;
}

.ot-plantilla:hover {
  color: white;
  text-decoration: none;
}

.ot-plantilla:active {
  text-decoration: none;
}
.medicion-de-espesores .ReactTable{
width: 100%!important;
margin-right: 15px;
}
.margin-top-de-tablas .ReactTable{
margin-top: 10px!important
}
.datos-de-campo{
align-items: center;
}
.observaciones-y-recomendaciones{
width: 90%;
min-height: 100px!important;
margin-left: 15px ;
margin-right: 15px ;
}
.BreadcrumbItem-color{
color: #000000;
font-weight: bold;
}
.margin-top-de-tablas{
margin-top: 10px
}
.margin-right-de-tablas{
margin-right: 10px;
}

.margin-left-de-tablas{
margin-left: 10px;
}
.margin-bottom-de-tablas{
margin-bottom: 10px;
}

.overflow-scroll{
overflow-y: scroll;
}
.customInputChart{
display: inline-block!important;
}
.boton-atras:hover{
/* color: rgb(18, 32, 163)!important; */
opacity: 0.9;
}
.boton-atras{
/* color: rgb(18, 32, 163)!important; */
opacity: 0.9;
align-items: center;
border: 1px solid #dee2e663!important;
}
.algo{
color: #3a3a3a;
}
.marcador-tabla-comparacion .ReactTable{
margin-right: 5px;
}
.modal-content-transparent-image{
margin-top: 70px!important; 
}
.joint-row-title {
text-align: center;
align-items: center;
color: rgba(32, 39, 140, 0.5);
width: 400px;
}
.joint-column-title {
text-align: center;
align-items: center;
color: rgba(32, 39, 140, 0.5);
min-width: 90px;
}
.boton-para-tabs{
  padding: 15px 20px;
  color: #000!important;
  /* background-color: #416288; */
  /* border: 1px solid rgb(214, 202, 202); */
}
.boton-para-tabs:hover{
  background-color:  #141e30!important;
  color: #fff!important;
}
.boton-para-tabs-activo{
  padding: 15px 20px;
  color: #ffffff!important;
  background-color:  #141e30!important;
}
.sombreadoDeHeader{
  box-shadow: 0px -2px 2px rgb(7, 7, 7)!important;
}
.popover {
  width: 350px;
  /* height: 320px; */
}

.card-ensayos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btn-add-image-integridad{
  background-color: #3983a8!important; 
  box-shadow: 0 0 2px #000!important;
  border-color: none!important;
  justify-content: center;
}
@media screen and (max-width: 768px){
  .btn-add-image-integridad{
    width: 100%!important;
  }
}
.boton-lista-fechas{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  background-color: #ffffff;
  /* border: 1px solid rgb(221, 224, 230); */
  padding: 7px 0;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  justify-content: space-around;
}

.boton-lista-fechas:hover{
  background-color: #e5e9f0;
  border: 1px solid #6f86ad;
  padding: 8px 0;
}

.btn-download-informe{
  background-color: green;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.btn-fecha{
  width: 100%; 
  max-height: 37px; 
  background-color: #3983a8; 
  box-shadow: 0 0 2px #000;
  color: #fff;
  padding: 15px;
  align-items: center;
  text-align: center;
  display: flex;
  border-radius: 5px;
}
.contenido-btn-fecha{
  font-size: 15px;
  margin-right: 5px;
  padding-right: 10px;
  border-right: 1px solid #fff ;
}
@media (min-width: 768px){
  .select-filtro-tabla{
    width: 100%;
    max-height: 30px;
    background-color: #3983a8!important; /*Color anterior: #264c8d*/
    color: #fff!important;
    box-shadow: 0 0 2px #000;
    margin-right: 5px;
    font-size: 13px!important;
  }
}
@media (max-width: 767px){
  .select-filtro-tabla{
    width: 100%;
    max-height: 30px;
    background-color: #3983a8!important; /*Color anterior: #264c8d*/
    color: #fff!important;
    box-shadow: 0 0 2px #000;
    margin-right: 3px;
    font-size: 14px!important;
  }
}
/*Empieza flecha y sus direcciones*/
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.rightArrow {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.leftArrow {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.upArrow {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.downArrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/*Termina flecha y sus direcciones*/
.btn-fecha .arrow{
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin: 0 4px;
}

/* @media (min-width: 600px) and (max-width: 840px){
  .responsive-table-grafica{
    width: 100%!important;
  }
} */
@media only screen and (min-width: 794px) and (max-width: 900px){
  .responsive-table-grafica{
    max-width: 100%!important;    
  }
}

/** estilos de botones exportación api570 **/
.export-buttons-container {
  display: flex;
  justify-content: center;
}

.export-buttons-container .button-space{
  margin: 25px;
}

.export-buttons-container .button-space a{
  margin-bottom: 10px;
}

.export-buttons-container .button-space p{
  font-size: medium;
  font-weight: 400;
}

.export-buttons-container .button-space .icon-button-excel{
  width: 100px;
  height: 100px;
  color: #316d3b;
}
.export-buttons-container .button-space .icon-button-excel:hover{
  opacity: 0.9;
}
.export-buttons-container .button-space .icon-button-xlsx{
  width: 100px;
  height: 100px;
  color: #4fa761;
}
.export-buttons-container .button-space .icon-button-xlsx:hover{
  opacity: 0.9;
}
.export-buttons-container .button-space .icon-button-pdf{
  width: 100px;
  height: 100px;
  color: #ae201f;
}
.export-buttons-container .button-space .icon-button-pdf:hover{
  opacity: 0.9;
}
.export-buttons-container .button-space .icon-button-img{
  width: 100px;
  height: 100px;
  color: #1597bb;
}
.export-buttons-container .button-space .icon-button-img:hover{
  opacity: 0.9;
}

.parameters-checkbox-list{
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: flex-start;
}

.parameters-checkbox-list-container{
  margin-left: 15%;
  flex-direction: row;
  justify-content: center;
}

.observations-button-area{
  margin-left: 2px;
  margin-right: 2px;
}