@mixin createCircle($colorCircle) {
    background-color: $colorCircle;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 2px;
}

@mixin floatField( $top, $left, $x, $y ) {
    position: absolute;
    top: $top;
    left: $left;
    width: 117px;
    height: 55px;
    background-color: #c2c2c2;
    transform: translate($x, $y);
    border: 0.5px #0f0e0f solid;
    .title {
        font-size: 12px;
        text-align: center;
        display: block;
        border-bottom: 1px solid #666560;
        background-color: #000;
        color: #ffffff;
        text-transform: uppercase;
    }
    .content {
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        display: block;
        margin-top: 6px;
    }
}

.nav-buttons-content {
    background-color: #f7f8f9;
    max-width: 350px;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50px;
    margin-bottom: 20px!important;
    padding: 5px 0;
    .nav-button-activos {
        border-radius: 50px;
        min-width: 150px;
        text-align: center;
        padding: 15px 20px;
        color: #ffffff!important;
        background-color:  #141e30!important;
    }
    .nav-button-activos-desactivado {
        padding: 15px 20px;
        color: #000!important;
        min-width: 150px;
        text-align: center;
    }
}

.drumVertical{
    max-height: 500px; 
}

.campo1H {
    @include floatField( 43%, 13%, -78%, -42% );
}
.campo2H {
    @include floatField( 43%, 54%, -90%, -42% );
}
.campo3H {
    @include floatField( 40%, 85%, -26%, -21% );
}
.campo4H {
    @include floatField( 82%, 56%, -26%, -21% );
}
.campo1V {
    @include floatField( 19%, 63%, -78%, -42% );
}
.campo2V {
    @include floatField( 43%, 67%, -90%, -42% );
}
.campo3V {
    @include floatField( 75%,42%, -26%, -21% );
}
.campo4V {
    @include floatField( 58%, 91%, -26%, -21% );
}

.gauge-leyend {
    ul {
        display: flex;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        justify-content: space-around;
        li {
            display: flex;
            align-items: center;
            font-size: 12px;
            .circle-green {
                @include createCircle(#66be33)
            }
            .circle-yelow {
                @include createCircle(#f1ca1c)
            }
            .circle-orange {
                @include createCircle(#e7961e)
            }
            .circle-red {
                @include createCircle(#d22928)
            }
        }
    }
}
